import api from "@utils/api";

export const getAllCarRegistry = () => api.get("/car_registry");
export const getCarRegistryByPlaceId = (id) => api.get(`/car_registry/place/${id}`);
export const getCarRegistryById = (id) => api.get(`/car_registry/${id}`);

export const createCarRegistry = (car) => api.post("/car_registry", car);
export const updateCarRegistry = (id, car) => api.put(`/car_registry/${id}`, car);
export const deleteCarRegistry = (id) => api.delete(`/car_registry/${id}`);
export const getCarRegistryByCarId = () => api.get(`/car_registry/car/${id}`, car);
export const getCarRegistryByPlaceIdPaginated = (id, page, per_page) =>
  api.get(`/car_registry/place/${id}?page=${page}&per_page=${per_page}`);

export const getCarRegistryPaginated = (page, per_page) => api.get(`/car_registry/?page=${page}&per_page=${per_page}`);
export const getCarByLicensePlate = (license_plate, page, per_page) =>
  api.get(`/car_registry/car/license_plate/${license_plate}?page=${page}&per_page=${per_page}`);