import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { getcars } from "@services/carService";
import { getPlaces } from "@services/placeService";
import { createCarRegistry } from "@services/carRegistryService";
import SuccessMessageModal from "@components/Reusable/SuccessMessageModal";
import WarningMessageModal from "@components/Reusable/WarningMessageModal";
import { AuthContext } from "@context/AuthContext";

const RegistryVehicleModal = ({ isOpen, onClose }) => {
  const { isAdminUser } = useContext(AuthContext);
  const [vehicleData, setVehicleData] = useState({
    car_id: "",
    date_time: new Date(),
    place_id: sessionStorage.getItem("selectedPlaceId")
      ? parseInt(sessionStorage.getItem("selectedPlaceId"), 10)
      : null,
    car_photo: null,
    plate_photo: null,
  });

  const [vehicles, setVehicles] = useState([]);
  const [places, setPlaces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [warningMessageVisible, setWarningMessageVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const NAME_ORGANIZATION = JSON.parse(
    sessionStorage.getItem("organization")
  );

  // Opciones de ejemplo, por si en el futuro se necesitan
  const eventTypeOptions = [
    { value: "Entrada", label: "Entrada" },
    { value: "Salida", label: "Salida" },
  ];

  useEffect(() => {
    if (isOpen) {
      loadVehicles();
      loadPlaces();
    }
  }, [isOpen]);

  const loadVehicles = async () => {
    setIsLoading(true);
    try {
      const response = await getcars(NAME_ORGANIZATION.organizacion_id, 1, 100);
      const vehicleOptions = response.data.map((car) => ({
        label: `${car.license_plate} - ${car.brand} (${car.model})`,
        value: car.id,
      }));
      setVehicles(vehicleOptions);
    } catch (error) {
      console.error("Error loading vehicles", error);
      setErrorMessage("Error al cargar vehículos.");
      setWarningMessageVisible(true);
    }
    setIsLoading(false);
  };

  const loadPlaces = async () => {
    if (isAdminUser) {
      try {
        const response = await getPlaces();
        const placeOptions = response.data.map((place) => ({
          label: place.name_place,
          value: place.id,
        }));
        setPlaces(placeOptions);
      } catch (error) {
        console.error("Error loading places", error);
        setErrorMessage("Error al cargar sedes.");
        setWarningMessageVisible(true);
      }
    }
  };

  const handleChangeVehicle = (selectedOption) => {
    setVehicleData((prev) => ({
      ...prev,
      car_id: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleChangePlace = (selectedOption) => {
    setVehicleData((prev) => ({
      ...prev,
      place_id: selectedOption ? selectedOption.value : "",
    }));
  };

  // Función para convertir un archivo a base64
  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Maneja la carga de la foto del vehículo
  const handleCarPhotoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const base64 = await toBase64(file);
      setVehicleData((prev) => ({ ...prev, car_photo: base64 }));
    }
  };

  // Maneja la carga de la foto de la placa
  const handlePlatePhotoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const base64 = await toBase64(file);
      setVehicleData((prev) => ({ ...prev, plate_photo: base64 }));
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (!vehicleData.car_id || !vehicleData.place_id) {
      setErrorMessage("Debes seleccionar el vehículo y la sede.");
      setWarningMessageVisible(true);
      return;
    }

    setIsLoading(true);
    try {
      await createCarRegistry(vehicleData);
      setIsLoading(false);
      setSuccessMessageVisible(true);
      setVehicleData({
        car_id: "",
        date_time: new Date(),
        place_id: "",
        car_photo: null,
        plate_photo: null,
      });
    } catch (error) {
      console.error("Error saving vehicle event:", error);
      setErrorMessage("Error al registrar el evento del vehículo.");
      setWarningMessageVisible(true);
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Registrar Evento del Vehículo</DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        ) : (
          <Box component="form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Vehículo</Typography>
                <Select
                  placeholder="Selecciona un vehículo"
                  options={vehicles}
                  value={vehicles.find(
                    (option) => option.value === vehicleData.car_id
                  )}
                  onChange={handleChangeVehicle}
                  isClearable
                  styles={{
                    control: (base) => ({
                      ...base,
                      minHeight: "40px",
                      fontSize: "16px",
                    }),
                    menuList: (base) => ({
                      ...base,
                      maxHeight: "150px",
                      overflowY: "auto",
                    }),
                  }}
                />
              </Grid>
              {isAdminUser && (
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Sede</Typography>
                  <Select
                    placeholder="Selecciona una sede"
                    options={places}
                    value={places.find(
                      (option) => option.value === vehicleData.place_id
                    )}
                    onChange={handleChangePlace}
                    isClearable
                    styles={{
                      control: (base) => ({
                        ...base,
                        minHeight: "40px",
                        fontSize: "16px",
                      }),
                      menuList: (base) => ({
                        ...base,
                        maxHeight: "150px",
                        overflowY: "auto",
                      }),
                    }}
                  />
                </Grid>
              )}

              {/* Inputs para adjuntar fotos */}
              <Grid item xs={12}>
                <Typography variant="subtitle1">Foto del Vehículo</Typography>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleCarPhotoChange}
                  style={{ marginTop: "8px" }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">Foto de la Placa</Typography>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handlePlatePhotoChange}
                  style={{ marginTop: "8px" }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: "#1b1b1b" }}>
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          style={{ backgroundColor: "#0A2139", color: "#f1f1f1" }}
          disabled={isLoading}
          variant="contained"
        >
          Guardar
        </Button>
      </DialogActions>

      <SuccessMessageModal
        isOpen={successMessageVisible}
        message="Evento del vehículo registrado exitosamente."
        onClose={() => window.location.reload()}
      />
      <WarningMessageModal
        isOpen={warningMessageVisible}
        message={errorMessage}
        onClose={() => setWarningMessageVisible(false)}
      />
    </Dialog>
  );
};

export default RegistryVehicleModal;
