import React, { createContext, useContext, useState } from "react";

// Crear el contexto
export const ModalContext = createContext();

// Hook personalizado para usar el contexto
export const useModal = () => useContext(ModalContext);

// Proveedor del contexto
export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState({
    createEmployeeModal: false,
    registerEmployeeModal: false,
    createOwnerModal: false,
    registryVehicleModal: false,
    createPlaceModal: false,
    createVehicleModal: false,
    createUserModal: false,
    manageUserModal: false,
    registryInventoryModal: false,
    createAssignmentModal: false,
    createTaskModal: false,
    // Puedes agregar más modales aquí
  });

  const openModal = (modalName) => {
    setModals((prev) => ({
      ...prev,
      [modalName]: true,
    }));
  };

  const closeModal = (modalName) => {
    setModals((prev) => ({
      ...prev,
      [modalName]: false,
    }));
  };

  return (
    <ModalContext.Provider value={{ modals, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};
