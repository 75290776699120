import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  FormControl,
} from "@mui/material";
import Select from "react-select";
import EventRegistration from "@components/EventCamera/EventRegistration";
import { createEmployee } from "@services/employeeService";
import { getPlaces } from "@services/placeService";
import SuccessMessageModal from "@components/Reusable/SuccessMessageModal";
import WarningMessageModal from "@components/Reusable/WarningMessageModal";
import { AuthContext } from "@context/AuthContext";

const CreateEmployeeModal = ({ isOpen, onClose }) => {
  const [newEmployee, setNewEmployee] = useState({
    first_name: "",
    second_name: "",
    last_name: "",
    second_last_name: "",
    cedula: "",
    photo: "",
    phone: "",
    place_id: "",
  });
  const [places, setPlaces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [warningMessageVisible, setWarningMessageVisible] = useState(false);
  const { isAdminUser } = useContext(AuthContext);
  const selectedPlaceId = sessionStorage.getItem("selectedPlaceId");

  useEffect(() => {
    if (isOpen) {
      loadPlaces();
    }
  }, [isOpen]);

  const loadPlaces = async () => {
    try {
      const response = await getPlaces();
      setPlaces(
        response.data.map((place) => ({
          label: place.name_place,
          value: place.id,
        }))
      );
    } catch (error) {
      console.error("Error loading places", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewEmployee((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePhotoCapture = (employee) => {
    setNewEmployee(employee);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (
      !newEmployee.first_name ||
      !newEmployee.last_name ||
      !newEmployee.cedula ||
      !newEmployee.phone ||
      !newEmployee.photo ||
      !newEmployee.place_id
    ) {
      setErrorMessage("Todos los campos con (*) son obligatorios.");
      setWarningMessageVisible(true);
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    try {
      await createEmployee(newEmployee);
      setIsLoading(false);
      setSuccessMessageVisible(true);
      setNewEmployee({
        first_name: "",
        second_name: "",
        last_name: "",
        second_last_name: "",
        cedula: "",
        photo: "",
        phone: "",
        place_id: "",
      });
    } catch (error) {
      setErrorMessage("Error al registrar el empleado.");
      setWarningMessageVisible(true);
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Crear Nuevo Empleado</DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box component="form">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Primer Nombre *"
                  name="first_name"
                  value={newEmployee.first_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Segundo Nombre"
                  name="second_name"
                  value={newEmployee.second_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Primer Apellido *"
                  name="last_name"
                  value={newEmployee.last_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Segundo Apellido"
                  name="second_last_name"
                  value={newEmployee.second_last_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Documento de Identificación *"
                  name="cedula"
                  type="number"
                  value={newEmployee.cedula}
                  onChange={handleChange}
                  fullWidth
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Celular *"
                  name="phone"
                  type="number"
                  value={newEmployee.phone}
                  onChange={handleChange}
                  fullWidth
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Select
                    placeholder="Sede *"
                    id="place_id"
                    name="place_id"
                    value={places.find(
                      (place) => place.value === newEmployee.place_id
                    )}
                    options={places}
                    onChange={(selectedOption) => {
                      setNewEmployee((prev) => ({
                        ...prev,
                        place_id: selectedOption.value,
                      }));
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Tomar foto *</Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                    padding: 2,
                  }}
                >
                  <EventRegistration
                    data={newEmployee}
                    onPhotoTaken={handlePhotoCapture}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: "#1d1d1b" }}>
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          style={{ backgroundColor: "#0A2139", color: "#f1f1f1" }}
          disabled={isLoading}
          variant="contained"
        >
          Guardar
        </Button>
      </DialogActions>

      <SuccessMessageModal
        message="Empleado registrado exitosamente."
        isOpen={successMessageVisible}
        onClose={() => window.location.reload()}
      />
      <WarningMessageModal
        message={errorMessage}
        isOpen={warningMessageVisible}
        onClose={() => setWarningMessageVisible(false)}
      />
    </Dialog>
  );
};

export default CreateEmployeeModal;
