import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import SuccessMessageModal from "@components/Reusable/SuccessMessageModal";
import WarningMessageModal from "@components/Reusable/WarningMessageModal";
// Importa tu servicio para crear tareas
import { createTask } from "@services/taskService";
// O donde tengas tu lógica para "organization" en caso de necesitarlo

/**
 * Este componente renderiza un Modal para crear una nueva Tarea.
 * @param {boolean} isOpen - Controla si el modal está abierto.
 * @param {function} onClose - Función para cerrar el modal.
 */
const CreateTaskModal = ({ isOpen, onClose }) => {
  // Estado que contendrá los datos de la nueva Tarea
  const [taskData, setTaskData] = useState({
    nombre: "",
    organizacion_id: 1, // Ajusta según tu lógica de organización
    descripcion: "",
  });

  // Estados de control
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  /**
   * Cuando se abra el modal, limpiamos el formulario.
   */
  useEffect(() => {
    if (isOpen) {
      setTaskData({
        nombre: "",
        organizacion_id: 1, // o el ID que corresponda
        descripcion: "",
      });
    }
  }, [isOpen]);

  /**
   * Maneja el cambio en los inputs de texto del formulario.
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTaskData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  /**
   * Ejecuta la lógica para crear la tarea.
   * Llama a la función createTask del servicio y maneja
   * los modales de éxito o de advertencia.
   */
  const handleSave = async () => {
    const { nombre, organizacion_id, descripcion } = taskData;

    // Validamos campos obligatorios
    if (!nombre || !descripcion || !organizacion_id) {
      setModalMessage("Todos los campos son obligatorios.");
      setIsWarningOpen(true);
      return;
    }

    try {
      setIsLoading(true);
      await createTask(taskData);
      setModalMessage("Tarea creada exitosamente.");
      setIsSuccessOpen(true);

      // Limpiamos el formulario
      setTaskData({
        nombre: "",
        organizacion_id: 1,
        descripcion: "",
      });
    } catch (error) {
      setModalMessage("Error al crear la tarea.");
      setIsWarningOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Cierra el modal de éxito, y también cierra el propio modal de creación
   * (si así lo deseas).
   */
  const handleSuccessClose = () => {
    setIsSuccessOpen(false);
    onClose();
    // Aquí podrías hacer una recarga o actualización de la lista de tareas
    // refreshTasks(); // si tienes una función para recargar tareas
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Nueva Tarea</DialogTitle>

      <DialogContent dividers>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box component="form" mt={2}>
            <Grid container spacing={2}>
              {/* Nombre de la Tarea */}
              <Grid item xs={12}>
                <TextField
                  label="Nombre de la Tarea *"
                  name="nombre"
                  value={taskData.nombre}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                  autoFocus
                />
              </Grid>

              {/* Descripción */}
              <Grid item xs={12}>
                <TextField
                  label="Descripción *"
                  name="descripcion"
                  value={taskData.descripcion}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        {/* Botón Cancelar */}
        <Button onClick={onClose} style={{ color: "#1d1d1b" }}>
          Cancelar
        </Button>

        {/* Botón Guardar */}
        <Button
          onClick={handleSave}
          style={{ backgroundColor: "#0A2139", color: "#f1f1f1" }}
          disabled={isLoading}
          variant="contained"
        >
          Guardar
        </Button>
      </DialogActions>

      {/* Modal de Éxito */}
      <SuccessMessageModal
        isOpen={isSuccessOpen}
        message={modalMessage}
        onClose={handleSuccessClose}
      />

      {/* Modal de Advertencia/Error */}
      <WarningMessageModal
        isOpen={isWarningOpen}
        message={modalMessage}
        onClose={() => setIsWarningOpen(false)}
      />
    </Dialog>
  );
};

export default CreateTaskModal;
