import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import EventRegistration from "../EventCamera/EventRegistration";
import { getEmployeeByDocument } from "@services/employeeService";
import { getPlaces } from "@services/placeService";
import SuccessMessageModal from "@components/Reusable/SuccessMessageModal";
import WarningMessageModal from "@components/Reusable/WarningMessageModal";
import { createEmployeeRegister } from "@services/employeeRegisterService";
import { AuthContext } from "@context/AuthContext";
import { getPlacesByUserNameParam } from "@services/userService";

const eventTypeOptions = [
  { value: "Entrada", label: "Entrada" },
  { value: "Salida", label: "Salida" },
];

const RegisterEventEmployee = ({ isOpen, onClose }) => {
  const selectedPlaceId = sessionStorage.getItem("selectedPlaceId");
  const [employeeData, setEmployeeData] = useState({
    employee_id: "",
    place_id: selectedPlaceId ? parseInt(selectedPlaceId, 10) : null,
    cedula_employee: "",
    event_type: { value: "Entrada", label: "Entrada" },
    photo_employee: "",
    date_time: new Date(),
  });
  const { isAdminUser } = useContext(AuthContext);

  const [places, setPlaces] = useState([]);
  const [employeeInfo, setEmployeeInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const USERNAME = sessionStorage.getItem("username");

  useEffect(() => {
    if (isOpen) {
      loadPlaces();
    }
  }, [isOpen]);

  const loadPlaces = async () => {
    try {
      const response = await getPlacesByUserNameParam(USERNAME);
      const placeOptions = response.data[0].places.map((place) => ({
        value: place.id,
        label: place.name_place,
      }));
      setPlaces(placeOptions);
    } catch (error) {
      console.error("Error loading places:", error);
      setModalMessage("Error al cargar sedes.");
      setIsWarningOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEventTypeChange = (selectedOption) => {
    setEmployeeData((prev) => ({
      ...prev,
      event_type: selectedOption,
    }));
  };

  const handlePlaceChange = (selectedOption) => {
    setEmployeeData((prev) => ({
      ...prev,
      place_id: selectedOption ? selectedOption.value : null,
    }));
  };

  const handlePhotoCapture = (photo) => {
    setEmployeeData((prev) => ({
      ...prev,
      photo_employee: photo.photo,
    }));
  };

  const handleCedulaValidation = async () => {
    setIsLoading(true);
    try {
      const response = await getEmployeeByDocument(
        employeeData.cedula_employee
      );
      if (response.status === 200) {
        setEmployeeInfo(response.data);
        setEmployeeData((prev) => ({
          ...prev,
          employee_id: response.data.id,
          cedula_employee: response.data.cedula,
        }));
      } else {
        setEmployeeInfo(null);
        setModalMessage("Empleado no encontrado.");
        setIsWarningOpen(true);
      }
    } catch (error) {
      console.error("Error fetching employee info:", error);
      setModalMessage("Error al buscar el empleado. Intenta de nuevo.");
      setIsWarningOpen(true);
      setEmployeeInfo(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    if (
      !employeeData.cedula_employee ||
      !employeeData.photo_employee ||
      !employeeData.place_id
    ) {
      setModalMessage(
        "Debes ingresar el número de cédula, tomar la foto y seleccionar una sede."
      );
      setIsWarningOpen(true);
      return;
    }

    setIsLoading(true);
    try {
      await createEmployeeRegister({
        ...employeeData,
        event_type: employeeData.event_type.value,
      });
      setIsLoading(false);
      setIsSuccessOpen(true);
      setModalMessage("Evento del empleado registrado exitosamente.");
      setEmployeeData({
        employee_id: "",
        place_id: null,
        cedula_employee: "",
        event_type: { value: "Entrada", label: "Entrada" },
        photo_employee: "",
        date_time: new Date(),
      });
    } catch (error) {
      console.error("Error saving employee event:", error);
      setModalMessage(
        "Error al registrar el evento del empleado o el empleado no existe."
      );
      setIsWarningOpen(true);
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Registrar Evento del Empleado</DialogTitle>
      <DialogContent dividers>
        <Box component="form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Ingrese el número de cédula"
                name="cedula_employee"
                value={employeeData.cedula_employee}
                onChange={handleChange}
                onBlur={handleCedulaValidation}
                onKeyDown={(e) => e.key === "Enter" && handleCedulaValidation()}
                fullWidth
                disabled={isLoading}
              />
            </Grid>
            {isLoading && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <CircularProgress />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Tipo de Evento</Typography>
              <Select
                value={employeeData.event_type}
                onChange={handleEventTypeChange}
                options={eventTypeOptions}
                placeholder="Seleccione el tipo de evento"
              />
            </Grid>
            {!selectedPlaceId && (
              <Grid item xs={12}>
                <Typography variant="subtitle1">Sede</Typography>
                <Select
                  value={places.find(
                    (place) => place.value === employeeData.place_id
                  )}
                  onChange={handlePlaceChange}
                  options={places}
                  placeholder="Seleccione una sede"
                  isClearable
                />
              </Grid>
            )}
            {employeeInfo && (
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  align="center"
                  sx={{ marginTop: 1 }}
                >
                  <strong>Nombre:</strong>{" "}
                  {`${employeeInfo.first_name} ${employeeInfo.second_name} ${employeeInfo.last_name} ${employeeInfo.second_last_name}`}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Tomar foto</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "100%",
                  padding: 2,
                }}
              >
                <EventRegistration onPhotoTaken={handlePhotoCapture} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: "#1b1b1b" }}>
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          style={{ backgroundColor: "#0A2139", color: "#f1f1f1" }}
          variant="contained"
          disabled={isLoading}
        >
          Guardar
        </Button>
      </DialogActions>
      <SuccessMessageModal
        isOpen={isSuccessOpen}
        message={modalMessage}
        onClose={() => window.location.reload()}
      />
      <WarningMessageModal
        isOpen={isWarningOpen}
        message={modalMessage}
        onClose={() => setIsWarningOpen(false)}
      />
    </Dialog>
  );
};

export default RegisterEventEmployee;
