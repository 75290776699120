import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { captureImageToBase64 } from "@utils/imageUtils";
import React, { useEffect, useState } from "react";

const EventRegistration = ({ data, onPhotoTaken }) => {
  const items = data;
  const [videoStream, setVideoStream] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [disabledCapture, setDisabledCapture] = useState(true);

  useEffect(() => {
    if (capturedImage) {
      const copyState = { ...items };
      copyState["photo"] = capturedImage;
      onPhotoTaken(copyState);
    }
  }, [capturedImage]);

  // Iniciar la cámara
  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setVideoStream(stream);
      const videoElement = document.getElementById("video");
      if (videoElement) {
        videoElement.srcObject = stream;
      }
    } catch (error) {
      console.error("Error al acceder a la cámara:", error);
    }
  };

  useEffect(() => {
    if (videoStream) {
      setDisabledCapture(false);
    } else {
      setDisabledCapture(true);
    }

    return () => {
      if (videoStream) {
        videoStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [videoStream]);

  // Capturar la imagen y convertirla en Base64
  const capturePhoto = async () => {
    const videoElement = document.getElementById("video");
    const base64Image = await captureImageToBase64(videoElement);
    setCapturedImage(base64Image);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={2}
      sx={{ padding: 2, maxWidth: 600, margin: "0 auto" }}
    >
      <Card sx={{ width: "100%", maxWidth: 400 }}>
        <CardContent>
          <Typography variant="h6" align="center">
            Cámara de Registro
          </Typography>
          <Box display="flex" justifyContent="center" marginBottom={2}>
            <video
              id="video"
              autoPlay
              playsInline
              style={{
                width: "100%",
                height: "auto",
                borderRadius: 8,
                backgroundColor: "#000",
              }}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" gap={1}>
            <Button
              onClick={startCamera}
              variant="contained"
              style={{ backgroundColor: "#0A2139", color: "#f1f1f1" }}
              fullWidth
            >
              Iniciar Cámara
            </Button>
            <Button
              onClick={capturePhoto}
              variant="contained"
              style={{ backgroundColor: "#0A2139", color: "#f1f1f1" }}
              fullWidth
              disabled={disabledCapture}
            >
              Capturar Imagen
            </Button>
          </Box>
        </CardContent>
      </Card>

      {capturedImage && (
        <Card sx={{ width: "100%", maxWidth: 300, marginTop: 2 }}>
          <CardMedia
            component="img"
            image={capturedImage}
            alt="Imagen Capturada"
            sx={{ borderRadius: 2 }}
          />
          <CardContent>
            <Typography variant="subtitle1" align="center">
              Imagen Capturada
            </Typography>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default EventRegistration;
