import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { createOwner } from "@services/ownerService";
import SuccessMessageModal from "@components/Reusable/SuccessMessageModal";
import WarningMessageModal from "@components/Reusable/WarningMessageModal";

const CreateOwnerModal = ({ isOpen, onClose }) => {
  const [newOwner, setNewOwner] = useState({
    first_name: "",
    second_name: "",
    first_last_name: "",
    second_last_name: "",
    cedula: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [warningMessageVisible, setWarningMessageVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewOwner((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // Validación básica
    if (!newOwner.first_name || !newOwner.first_last_name || !newOwner.cedula) {
      setErrorMessage("Todos los campos con (*) son obligatorios.");
      setWarningMessageVisible(true);
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    try {
      await createOwner(newOwner);
      setIsLoading(false);
      setSuccessMessageVisible(true);
      setNewOwner({
        first_name: "",
        second_name: "",
        first_last_name: "",
        second_last_name: "",
        cedula: "",
      });
    } catch (error) {
      setErrorMessage("Error al registrar el propietario. Intente de nuevo.");
      setWarningMessageVisible(true);
      setIsLoading(false);
      console.error("Error al crear el propietario:", error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Registrar Nuevo Propietario</DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box component="form">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Primer Nombre *"
                  name="first_name"
                  value={newOwner.first_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Segundo Nombre"
                  name="second_name"
                  value={newOwner.second_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Primer Apellido *"
                  name="first_last_name"
                  value={newOwner.first_last_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Segundo Apellido"
                  name="second_last_name"
                  value={newOwner.second_last_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Cédula *"
                  name="cedula"
                  type="number"
                  value={newOwner.cedula}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: "#1d1d1b" }}>
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          style={{ backgroundColor: "#0A2139", color: "#f1f1f1" }}
          disabled={isLoading}
          variant="contained"
        >
          Guardar
        </Button>
      </DialogActions>

      <SuccessMessageModal
        message="Propietario registrado exitosamente."
        isOpen={successMessageVisible}
        onClose={() => window.location.reload()}
      />
      <WarningMessageModal
        message={errorMessage}
        isOpen={warningMessageVisible}
        onClose={() => setWarningMessageVisible(false)}
      />
    </Dialog>
  );
};

export default CreateOwnerModal;
