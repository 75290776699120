import api from "@utils/api";

export const createAssignment = (assignment) => api.post("/asignaciones/", assignment);
export const getAssignmentsById = (id) => api.get(`/asignaciones/${id}`);
export const updateAssignment = (id, assignment) => api.put(`/asignaciones/${id}`, assignment);
export const deleteAssignment = (id) => api.delete(`/asignaciones/${id}`);

export const updateAssignmentStatus = (id, status) => api.put(`/asignaciones/${id}/estado?estado=${status}`, status);

export const getAssignmentsByEmployeeId = (id) => api.get(`/asignaciones/colaborador/${id}`);

export const getAssignmentsByEmployeePlace = (id, idPlace, page, perPage) =>
  api.get(`/asignaciones/colaborador/${id}/sede/${idPlace}?page=${page}&per_page=${perPage}`);

export const getAssignmentsByEmployeeStatus = (id, status, page, perPage) => 
    api.get(`/asignaciones/colaborador/${id}/estado?estado=${status}&page=${page}&per_page=${perPage}`);

export const getAssignmentsByEmployeeIdAndStatusAndAssignmentDate = (
  idEmployee,
  status,
  startDate,
  endDate,
  page,
  perPage
) =>
  api.get(
    `/asignaciones/colaborador/${idEmployee}/estado/fecha_asignacion?estado=${status}&fecha_inicio=${startDate}&fecha_fin=${endDate}&page=${page}&per_page=${perPage}`);

export const getAssignmentsByEmployeeIdAndStatusAndAssignmentEndDate = (
    idEmployee, status, startDate,endDate, page, perPage ) =>
    api.get(`/asignaciones/colaborador/${idEmployee}/estado/fecha_finalizacion?estado=${status}&fecha_inicio=${startDate}&fecha_fin=${endDate}&page=${page}&per_page=${perPage}`);

export const getAssignmentsByEmployeeIdStatusDevelopDate = (
  idEmployee,
  status,
  startDate,
  endDate,
  page,
  perPage
) =>
  api.get(`/asignaciones/colaborador/${idEmployee}/estado/fecha_desarrollo?estado=${status}&fecha_inicio=${startDate}&fecha_fin=${endDate}&page=${page}&per_page=${perPage}`);

export const getAssigmentsByPlace = (idPlace, page, perPage) =>
  api.get(`/asignaciones/sede/${idPlace}?page=${page}&per_page=${perPage}`);

export const getAssigmentsByOrganization = (idOrg, page, perPage) =>
  api.get(
    `/asignaciones/organizacion/${idOrg}?page=${page}&per_page=${perPage}`
  );
  