import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import Select from "react-select";
import SuccessMessageModal from "@components/Reusable/SuccessMessageModal";
import WarningMessageModal from "@components/Reusable/WarningMessageModal";
import { createAssignment } from "@services/assignmentService";
import { getTasksPaginated } from "@services/taskService";
import { getEmployees } from "@services/employeeService";
import { getPlaces } from "@services/placeService";

const CreateAssignmentModal = ({ isOpen, onClose }) => {
  const selectedPlaceId = sessionStorage.getItem("selectedPlaceId");
  const ORGANIZATION = JSON.parse(sessionStorage.getItem("organization"));

  // Datos del formulario
  const [assignmentData, setAssignmentData] = useState({
    tarea_id: 0,
    colaborador_id: 0,
    sede_id: selectedPlaceId,
    descripcion: "",
    fecha_asignacion: "",
    fecha_desarrollo: "",
    fecha_finalizacion: "",
    tiempo_ejecucion: 0,
    estado: "por hacer",
  });

  // Listas de datos para los selects
  const [tasks, setTasks] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [sedes, setSedes] = useState([]);

  // Estados de control
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  // Opciones de estado para react-select
  const estadoOptions = [
    { value: "por hacer", label: "Por hacer" },
    { value: "en desarrollo", label: "En desarrollo" },
    { value: "finalizada", label: "Finalizada" },
  ];

  /**
   * Al abrir el modal, limpiamos el formulario y cargamos la data si hace falta.
   */
  useEffect(() => {
    if (isOpen) {
      // Limpiamos el formulario
      setAssignmentData({
        tarea_id: 0,
        colaborador_id: 0,
        sede_id: 0,
        descripcion: "",
        fecha_asignacion: "",
        fecha_desarrollo: "",
        fecha_finalizacion: "",
        tiempo_ejecucion: 0,
        estado: "por hacer",
      });

      // Cargamos las listas
      loadTasks();
      loadEmployees();
      loadSedes();
    }
  }, [isOpen]);

  // =======================
  // Carga de datos
  // =======================
  const loadTasks = async () => {
    try {
      // Llamamos a getTasksPaginated con organizacion_id, page=1, per_page=100
      // (así obtienes muchas tareas sin preocuparte por la paginación)
      const response = await getTasksPaginated(
        ORGANIZATION.organizacion_id,
        1,
        1000
      );
      // Tu response: { data: { page, per_page, data: [ { id, nombre, ... } ] } }
      // Extraemos el array de tareas de response.data.data
      const tasksArray = response.data.data;
      setTasks(tasksArray);
    } catch (error) {
      console.error("Error al cargar Tareas:", error);
    }
  };

  const loadEmployees = async () => {
    try {
      const response = await getEmployees(ORGANIZATION.organizacion_id);
      setEmployees(response.data);
    } catch (error) {
      console.error("Error al cargar Empleados:", error);
    }
  };

  const loadSedes = async () => {
    try {
      // getPlaces(organizacion_id) ->
      // devuelves un array: [ { id, name_place, ... }, ... ]
      const response = await getPlaces(ORGANIZATION.organizacion_id);
      setSedes(response.data);
    } catch (error) {
      console.error("Error al cargar Sedes:", error);
    }
  };

  /**
   * Handle para inputs tipo texto/number (tiempo_ejecucion, descripcion, fechas)
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAssignmentData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  /**
   * Manejadores de los react-select
   */
  const handleTaskChange = (selectedOption) => {
    setAssignmentData((prev) => ({
      ...prev,
      tarea_id: selectedOption ? selectedOption.value : 0,
    }));
  };

  const handleEmployeeChange = (selectedOption) => {
    setAssignmentData((prev) => ({
      ...prev,
      colaborador_id: selectedOption ? selectedOption.value : 0,
    }));
  };

  const handleSedeChange = (selectedOption) => {
    setAssignmentData((prev) => ({
      ...prev,
      sede_id: selectedOption ? selectedOption.value : 0,
    }));
  };

  const handleEstadoChange = (selectedOption) => {
    setAssignmentData((prev) => ({
      ...prev,
      estado: selectedOption ? selectedOption.value : "por hacer",
    }));
  };

  /**
   * Crea la asignación
   */
  const handleSave = async () => {
    const {
      tarea_id,
      colaborador_id,
      sede_id,
      descripcion,
      fecha_asignacion,
      fecha_desarrollo,
      fecha_finalizacion,
      tiempo_ejecucion,
      estado,
    } = assignmentData;

    // Validación de campos obligatorios
    if (
      !tarea_id ||
      !colaborador_id ||
      !sede_id ||
      !descripcion ||
      !fecha_asignacion ||
      !fecha_desarrollo ||
      !fecha_finalizacion ||
      !estado
    ) {
      setModalMessage("Por favor, completa todos los campos obligatorios.");
      setIsWarningOpen(true);
      return;
    }

    try {
      setIsLoading(true);
      await createAssignment(assignmentData);

      setModalMessage("Asignación creada exitosamente.");
      setIsSuccessOpen(true);

      // Resetea el formulario
      setAssignmentData({
        tarea_id: 0,
        colaborador_id: 0,
        sede_id: 0,
        descripcion: "",
        fecha_asignacion: "",
        fecha_desarrollo: "",
        fecha_finalizacion: "",
        tiempo_ejecucion: 0,
        estado: "por hacer",
      });
    } catch (error) {
      console.error(error);
      setModalMessage("Error al crear la asignación.");
      setIsWarningOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Cierra el modal de éxito y el modal principal.
   */
  const handleSuccessClose = () => {
    setIsSuccessOpen(false);
    onClose();
    // refreshAssignments(); // Si deseas recargar la lista principal
  };

  // =========================================
  // Construimos opciones para react-select
  // =========================================

  // Tasks vienen de: tasks = [{id, nombre, ...}, ...]
  const taskOptions = tasks.map((t) => ({
    value: t.id,
    label: t.nombre,
  }));

  // Empleados: employees = [{id, first_name, second_name, last_name, second_last_name, ...}, ...]
  // Construimos el nombre completo
  const employeeOptions = employees.map((emp) => {
    const fullName = [
      emp.first_name,
      emp.second_name,
      emp.last_name,
      emp.second_last_name,
    ]
      .filter(Boolean) // quita valores vacíos
      .join(" ");
    return { value: emp.id, label: fullName };
  });

  // Sedes: sedes = [{id, name_place, ...}, ...]
  const sedeOptions = sedes.map((s) => ({
    value: s.id,
    label: s.name_place,
  }));

  // Buscamos la opción seleccionada en cada select
  const selectedTaskOption =
    taskOptions.find((option) => option.value === assignmentData.tarea_id) ||
    null;

  const selectedEmployeeOption =
    employeeOptions.find(
      (option) => option.value === assignmentData.colaborador_id
    ) || null;

  const selectedSedeOption =
    sedeOptions.find((option) => option.value === assignmentData.sede_id) ||
    null;

  const selectedEstadoOption =
    estadoOptions.find((option) => option.value === assignmentData.estado) ||
    null;

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Nueva Asignación</DialogTitle>

      <DialogContent dividers>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box component="form" mt={2}>
            <Grid container spacing={2}>
              {/* Select Tarea */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Tarea *</Typography>
                <Select
                  options={taskOptions}
                  value={selectedTaskOption}
                  onChange={handleTaskChange}
                  placeholder="Seleccione una tarea"
                  isClearable
                />
              </Grid>

              {/* Select Colaborador */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Colaborador *</Typography>
                <Select
                  options={employeeOptions}
                  value={selectedEmployeeOption}
                  onChange={handleEmployeeChange}
                  placeholder="Seleccione un colaborador"
                  isClearable
                />
              </Grid>

              {/* Select Sede */}
              {!selectedPlaceId && <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Sede *</Typography>
                <Select
                  options={sedeOptions}
                  value={selectedSedeOption}
                  onChange={handleSedeChange}
                  placeholder="Seleccione una sede"
                  isClearable
                />
              </Grid>}
              {/* Select Estado */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Estado *</Typography>
                <Select
                  options={estadoOptions}
                  value={selectedEstadoOption}
                  onChange={handleEstadoChange}
                  placeholder="Seleccione el estado"
                  isClearable
                />
              </Grid>

              {/* Descripción */}
              <Grid item xs={12}>
                <TextField
                  label="Descripción *"
                  name="descripcion"
                  value={assignmentData.descripcion}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                />
              </Grid>

              {/* Tiempo de ejecución */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Tiempo de Ejecución (min) *"
                  name="tiempo_ejecucion"
                  value={assignmentData.tiempo_ejecucion}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                  type="number"
                />
              </Grid>

              {/* Fecha de Asignación */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Fecha Asignación *"
                  name="fecha_asignacion"
                  type="datetime-local"
                  value={assignmentData.fecha_asignacion}
                  onChange={handleInputChange}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              {/* Fecha de Desarrollo */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Fecha Desarrollo *"
                  name="fecha_desarrollo"
                  type="datetime-local"
                  value={assignmentData.fecha_desarrollo}
                  onChange={handleInputChange}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              {/* Fecha de Finalización */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Fecha Finalización *"
                  name="fecha_finalizacion"
                  type="datetime-local"
                  value={assignmentData.fecha_finalizacion}
                  onChange={handleInputChange}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} style={{ color: "#1d1d1b" }}>
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          style={{ backgroundColor: "#0A2139", color: "#f1f1f1" }}
          disabled={isLoading}
          variant="contained"
        >
          Guardar
        </Button>
      </DialogActions>

      {/* Modal de Éxito */}
      <SuccessMessageModal
        isOpen={isSuccessOpen}
        message={modalMessage}
        onClose={handleSuccessClose}
      />

      {/* Modal de Advertencia/Error */}
      <WarningMessageModal
        isOpen={isWarningOpen}
        message={modalMessage}
        onClose={() => setIsWarningOpen(false)}
      />
    </Dialog>
  );
};

export default CreateAssignmentModal;
