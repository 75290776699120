import api from "@utils/api";
const ORGANIZACION = JSON.parse(sessionStorage.getItem("organization"));

//Categories
export const getCategories = (page, per_page) =>
  api.get(`/categories/organizacion/${ORGANIZACION.organizacion_id}?page=${page}&per_page=${per_page}`);
export const getCategoryById = (id) => api.get(`/categories/${id}`);
export const getCountProductsByCategory = () => api.get(`categories/products/count_product`);
export const createCategory = (category) => api.post("/categories/", category);
export const updateCategory = (id, category) => api.put(`/categories/${id}`, category);
export const deleteCategory = (id) => api.delete(`/categories/${id}`);

//products
export const getProducts = (page, per_page) => api.get(`products/organizacion/${ORGANIZACION.organizacion_id}?page=${page}&per_page=${per_page}`);
export const getProductById = (id) => api.get(`/products/${id}`);
export const createProduct = (product) => api.post("/products/", product);
export const updateProduct = (id, product) => api.put(`/products/${id}`, product);
export const deleteProduct = (id) => api.delete(`/products/${id}`);
export const getProductByCategory = (category_id) => api.get(`/products/category/${category_id}`);

//stocks
export const getStockByProductByPlace = (product_id, place_id) => api.get(`/stock/product_place/${product_id}/${place_id}`);
export const getStockById = (id) => api.get(`/stock/${id}`);
export const getStockByProduct = (product_id) => api.get(`/stock/product/${product_id}`);
export const getStockByPlace = (place_id) => api.get(`/stock/place/${place_id}`);
export const updateStockById = (id, stock) => api.put(`/stock/${id}`, stock);
export const createStock = (stock) => api.post("/stock/", stock);
export const deleteStock = (id) => api.delete(`/stocks/${id}`);
export const getBulkUpdateStock = (stock) => api.put("/stock/bulk", stock);
export const getStock = () => api.get(`/stock/organizacion/${ORGANIZACION.organizacion_id}?page=1&per_page=1000`);

//product_registry
export const getProductRegistry = () => api.get(`/product_registry/organizacion/${ORGANIZACION.organizacion_id}?page=1&per_page=1000`);
export const createProductRegistry = (product_registry) => api.post("/product_registry/", product_registry);
export const getProductRegistryById = (id) => api.get(`/product_registry/${id}`);
export const getProductRegistryByPlaceByProduct = (place_id, product_id) => api.get(`/product_registry/product_place/${product_id}/${place_id}`);
export const getProductRegistryByDateRange = (start_date, end_date) => api.get(`/product_registry/date_range/${start_date}/${end_date}`);
export const getProductRegistryByPlace = (place_id) => api.get(`/product_registry/place/${place_id}`);
export const getProductRegistryByProduct = (product_id) => api.get(`/product_registry/product/${product_id}`);
export const deleteProductRegistry = (id) => api.delete(`/product_registry/${id}`);
export const updateProductRegistry = (id, product_registry) => api.put(`/product_registry/${id}`, product_registry);