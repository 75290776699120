import CreateEmployeeModal from "@components/Employee/CreateEmployeeModal";
import CreateOwnerModal from "@components/Owner/CreateOwnerModal";
import CreatePlaceModal from "@components/Place/CreatePlaceModal";
import RegisterEventEmployee from "@components/RegisterEventEmployee/RegisterEventEmployee";
import WarningMessageModal from "@components/Reusable/WarningMessageModal";
import CreateUserModal from "@components/User/CreateUserModal";
import ManageUserModal from "@components/User/ManageUserModal";
import RegistryVehicleModal from "@components/Vehicles/RegistryVehicleModal";
import RegistryEventInventory from "@components/Inventary/ProductRegistry/RegistryEventInventory";
import CreateTaskModal from "@components/Task/CreateTaskModal";
import CreateAssignmentModal from "@components/Assign/CreateAssignmentModal";
import { AuthContext } from "@context/AuthContext";
import { ModalProvider, useModal } from "@context/ModalContext";
import React, { Suspense, lazy, useContext, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "@styles/App.css";

// Lazy loading de las rutas principales
const Dashboard = lazy(() => import("@pages/Dashboard"));
const Employees = lazy(() => import("@pages/Employees"));
const Login = lazy(() => import("@pages/Login"));
const Places = lazy(() => import("@pages/Places"));
const Vehicles = lazy(() => import("@pages/Vehicles"));
const EmployeeEvent = lazy(() => import("@pages/EmployeeEvent"));
const PrivateRoute = lazy(() => import("@components/PrivateRoute"));
const  Owners = lazy(() => import("@pages/Owners"));
const  PlacesById = lazy(() => import("@pages/PlaceById"));
const  UserPage = lazy(() => import("@pages/UserPage"));
const  VehicleEvent = lazy(() => import("@pages/VehicleEvent"));
const  Inventory = lazy(() => import("@pages/Inventory"));
const  Assignment = lazy(() => import("@pages/Assignment"));
const Task = lazy(() => import("@pages/Task"));

const Modals = () => {
  const { modals, closeModal } = useModal();

  return Object.entries(modals).map(([modalName, isOpen]) => {
    const ModalComponent = {
      createEmployeeModal: CreateEmployeeModal,
      registerEventEmployee: RegisterEventEmployee,
      registryVehicleModal: RegistryVehicleModal,
      createOwnerModal: CreateOwnerModal,
      createPlaceModal: CreatePlaceModal,
      createUserModal: CreateUserModal,
      manageUserModal: ManageUserModal,
      registryInventoryModal: RegistryEventInventory,
      createAssignmentModal: CreateAssignmentModal,
      createTaskModal: CreateTaskModal,
    }[modalName];
    return (
      isOpen && (
        <ModalComponent
          key={modalName}
          isOpen={isOpen}
          onClose={() => closeModal(modalName)}
        />
      )
    );
  });
};

const App = () => {
  const { isAuthenticated, isAdminUser } = useContext(AuthContext);
  const location = useLocation();



  return (
    <ModalProvider>
      <Modals />
      <Suspense fallback={<div>Redireccionando...</div>}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/empleados" element={<Employees />} />
            <Route path="/sedes" element={<Places />} />
            <Route path="/sedes/:id" element={<PlacesById />} />
            <Route path="/vehiculos" element={<Vehicles />} />
            <Route path="/propietarios" element={<Owners />} />
            <Route path="/eventoEmpleado" element={<EmployeeEvent />} />
            <Route path="/inventario/*" element={<Inventory />} />
            <Route path="/vehiculosRegistrados" element={<VehicleEvent />} />
            <Route path="/asignaciones" element={<Assignment />} />
            <Route path="/tareas" element={<Task />} />
            {!isAdminUser && (
              <Route
                path="/sedes/"
                element={
                  <ModalProvider>
                    <WarningMessageModal
                      isOpen={true}
                      onClose={() => {
                        window.location.href = "/dashboard";
                      }}
                      title="Acceso denegado"
                      message="No tienes permisos de administrador para acceder a esta sección."
                    />
                  </ModalProvider>
                }
              />
            )}
            <Route path="/usuarios" element={<UserPage />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </ModalProvider>
  );
};

export default App;
