import api from "@utils/api";

export const getEmployees = (id) => api.get(`/employee/organizacion/${id}`);
export const getEmployeeById = (id) => api.get(`/employee/${id}`);
export const getEmployeeByPlaceId = (id) => api.get(`/employee/place/${id}`);
export const createEmployee = (employee) => api.post("/employee", employee);
export const updateEmployee = (id, employee) =>
  api.put(`/employee/${id}`, employee);
export const deleteEmployee = (id) => api.delete(`/employee/${id}`);

export const getEmployeeByDocument = (document) =>
  api.get(`/employee/cedula/${document}`);